import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./CreateContract.module.scss";
import { generatePath, useSearchParams } from "react-router-dom";
import { dataContracts } from "../../data/dataContracts";
import { CORRELATION_ID_KEY } from "./RoutingPage";
import { ContractId, CorrelationId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { getRandomInt } from "../../components/utils";
import { CONTRACT_ROUTE } from "../sales/Contract/ContractPage";
import { Beacon } from "../../components/beacon/Beacon";
import { Validity } from "../../data/models/ContractTypes";
import { Pending } from "../../components/icons/Pending";
import { Trans } from "react-i18next";
import { Disabled } from "../../components/icons/Disabled";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { Link } from "../../components/links/Link";
import { T } from "../../components/translation/T";
import { Button } from "../../components/interactions/Buttons/Button";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { InfoBox } from "../../components/boxes/InfoBox";
import { useSetAtom } from "jotai";
import { contractExistingDataState } from "../../state/contractExistingData";

interface Props {}

enum Error {
  CONTRACT_ALREADY_STARTED = "CONTRACT_ALREADY_STARTED",
  NO_CASE_FOUND = "NO_CASE_FOUND",
  PRIMARY_CONTACT_NOT_VALID = "PRIMARY_CONTACT_NOT_VALID",
  COUNTRY_NOT_VALID = "COUNTRY_NOT_VALID",
  ORGANIZATION_NUMBER_NOT_VALID = "ORGANIZATION_NUMBER_NOT_VALID",
  LEGAL_ENTITY_TYPE_NOT_ALLOWED = "LEGAL_ENTITY_TYPE_NOT_ALLOWED",
  COMPANY_NOT_ACTIVE = "COMPANY_NOT_ACTIVE",
  FAILED_ROARING_REQUEST_STATUS = "FAILED_ROARING_REQUEST_STATUS",
  COMPANY_DATA_NOT_AVAILABLE = "COMPANY_DATA_NOT_AVAILABLE",
}

function getRoute(contractId: ContractId) {
  return generatePath(CONTRACT_ROUTE, {
    contractId,
  });
}

function getError(error: Error) {
  if (error === Error.CONTRACT_ALREADY_STARTED) {
    return "This contract has already been created. Nothing to do here.";
  }

  if (error === Error.NO_CASE_FOUND) {
    return "We couldn't find a valid case file. Unfortunately, there is nothing we can do at this moment. Please check so that this is a valid contract in Salesforce.";
  }

  if (error === Error.PRIMARY_CONTACT_NOT_VALID) {
    return "The primary contact associated with this contract is not valid. Unfortunately, there is nothing we can do at this moment. Please update this contract in Salesforce so that it has a valid primary contact.";
  }

  if (error === Error.COUNTRY_NOT_VALID) {
    return "This contract is associated with a country that we don't yet support. Unfortunately, there is nothing we can do at this moment.";
  }

  if (error === Error.ORGANIZATION_NUMBER_NOT_VALID) {
    return "This contract has a identification number that is not valid. Unfortunately, there is nothing we can do.";
  }

  if (error === Error.LEGAL_ENTITY_TYPE_NOT_ALLOWED) {
    return "This company has a legal entity type that we don't yet support.";
  }

  if (error === Error.COMPANY_NOT_ACTIVE) {
    return "This company is not active. Unfortunately, there is nothing we can do.";
  }

  if (error === Error.COMPANY_DATA_NOT_AVAILABLE) {
    return "Strange. No information of this company was found. Perhaps this company is of a legal entity form that we currently don't support. Unfortunately, there is nothing we can do.";
  }

  return error || "Unkown error. My, oh my. Contact your local developer.";
}

function getIcon(status: Status | Error) {
  if (status === Status.SUCCESS) {
    return <Beacon validity={Validity.VALID} mini />;
  }

  if (status === Status.ERROR) {
    return <Beacon validity={Validity.MISSING} mini />;
  }

  if (status === Status.PENDING) {
    return <Pending />;
  }

  if (status === Status.DISABLED) {
    return <Disabled className={styles.disabled} />;
  }

  if (status) {
    return <Beacon validity={Validity.MISSING} mini />;
  }

  return <Disabled className={styles.disabled} />;
}

async function delay(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}
export const CreateContract: React.FunctionComponent<Props> = () => {
  const setExistingData = useSetAtom(contractExistingDataState);
  const [params] = useSearchParams();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [statusRoaring, setStatusRoaring] = useState<Status>(Status.PENDING);
  const [statusContract, setStatusContract] = useState<Status>(Status.PENDING);
  const [error, setError] = useState<Error | null>(null);
  const [url, setUrl] = useState<string>();

  const load = useCallback(async () => {
    const correlationId = params.get(CORRELATION_ID_KEY);

    if (!correlationId) {
      // We have already confirmed that we have correlation id in RoutingPage
      return;
    }

    try {
      const response = await dataContracts.createContract(
        correlationId as CorrelationId
      );

      setExistingData(response.existingMerchantData);

      await delay(getRandomInt(500, 2000));
      setStatusRoaring(Status.SUCCESS);

      await delay(getRandomInt(500, 2000));
      setStatusContract(Status.SUCCESS);

      await delay(500);
      setUrl(getRoute(response.contractId));
    } catch (err) {
      await delay(getRandomInt(500, 1000));

      if (!err || !(err as any).data) {
        setStatusRoaring(Status.DISABLED);
        setStatusContract(Status.DISABLED);
        setStatus(Status.ERROR);
        return;
      }

      const { error, message } = (err as any).data;

      if (error === Error.FAILED_ROARING_REQUEST_STATUS) {
        setStatusRoaring(Status.ERROR);
        await delay(getRandomInt(400, 800));
        setStatusContract(Status.DISABLED);
        setStatus(Status.ERROR);
        return;
      }

      setStatusRoaring(Status.SUCCESS);
      await delay(getRandomInt(400, 800));
      setStatusContract(Status.ERROR);
      setStatus(Status.ERROR);
      setError(error || message);
    }
  }, [params, setExistingData]);

  useEffect(() => {
    setTimeout(load, 500);
  }, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setStatusRoaring(Status.PENDING);
    setStatusContract(Status.PENDING);
    load();
  }, [load]);

  const elem = useMemo(() => {
    if (process.env.REACT_APP_ENV === "production") {
      return null;
    }

    return (
      <div className="m-top-40">
        <InfoBox relative>
          <T>We're in the test environment. Feel free to break things</T>
          <br />
          <span className="text-large">🔨🪓🧨💣</span>
        </InfoBox>
      </div>
    );
  }, []);

  return (
    <div className={styles.list}>
      <h1>
        <T>Creating contract</T>
      </h1>

      {elem}

      <ul className="m-top-40">
        <li>
          <div className={styles.icon}>{getIcon(statusRoaring)}</div>
          <Trans>
            Request <b>Roaring</b> data
          </Trans>
        </li>
        <li>
          <div className={styles.icon}>{getIcon(statusContract)}</div>
          <Trans>
            Lookup <b>Contract</b> status
          </Trans>
        </li>
      </ul>

      <div className="m-top-40">
        <AnimateHeight name={status + error}>
          <div>
            {status === Status.ERROR && statusRoaring === Status.ERROR && (
              <>
                <div>
                  <strong>
                    <T>Ooh noo!</T>
                  </strong>{" "}
                  😔
                  <br />
                  <T>
                    Failed to fetch company data from the registry. Don't know
                    why. Try again?
                  </T>
                  <div className="m-top-30">
                    <Button block onClick={retry}>
                      <T>Retry</T>
                    </Button>
                  </div>
                </div>
              </>
            )}

            {status === Status.ERROR && !!error && (
              <>
                <div>
                  <strong>
                    <T>Ooh noo!</T>
                  </strong>{" "}
                  😔
                  <br />
                  <T>{getError(error)}</T>
                </div>
              </>
            )}

            {status === Status.ERROR &&
              statusRoaring !== Status.ERROR &&
              !error && (
                <>
                  <ErrorBox relative>
                    <strong>
                      <T>Ooh noo!</T>
                    </strong>{" "}
                    😔
                    <br />
                    <T>Something went wrong.</T>
                  </ErrorBox>
                  <div className="m-top-30">
                    <Button block onClick={retry}>
                      <T>Retry</T>
                    </Button>
                  </div>
                </>
              )}

            {url && (
              <>
                <div className="center">
                  <b>
                    <T>Lovely!</T>
                  </b>
                  &nbsp; <T>Contract was created.</T> &nbsp;
                </div>
                <div className="center">
                  <Link className="m-top-10 as-button" link={url}>
                    <T>Get me there</T>
                  </Link>
                </div>
              </>
            )}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};
